import imagesLoaded from 'imagesloaded';
import barba from '@barba/core';
import barbaPrefetch from '@barba/prefetch';

import gsap from '../lib/gsap.min';
import ScrollTrigger from '../lib/ScrollTrigger.min';
import ScrollToPlugin from '../lib/ScrollToPlugin.min';
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

import Swiper from 'swiper/bundle';

export function main() {
	
var Module = {};
var body;

Module.Compornent = {
	mobileToggleAsset: false,
	checkClass: function($this){
		$this = null;
	}
};

(function($) {
Module.Events = (function (){
'use strict';

var OBSERVER = {
	handlers: [],
	observeEvents: function(target){
		this.handlers.push(target);
	},
	deleteListener: function(element,listener){
		element.off(listener);
		element = null;
		listener = null;
	},
	clearEvents: function(){
		$(window).off('scroll resize');
		cancelAnimationFrame(timeReq);
	}
};

var winH = $(window).height();
var winW = $(window).width();
var body = $('body');


function elFunc(){
	let mm = gsap.matchMedia();
	document.querySelectorAll('.el').forEach((_tar, i) => {
		let _elmc = _tar.querySelectorAll('.elc');
		if( _elmc.length == 0 ){
			_elmc = _tar;
		}
		gsap.fromTo(_elmc,
			{ opacity:0, y:60 },
			{ opacity:1, y:0, 
				duration: 1.2,
				ease: "power4.out",
 				stagger: 0.1,
				scrollTrigger: {
					trigger: _tar,
					start: "top bottom-=10%",
					onRefresh: self => self.progress === 1 && self.animation.progress(1)
				}
			}
		);
	});

	const up_scrollTrigger = ScrollTrigger.create({
		trigger: body,
		start: "top top",
		end: 'top bottom',
		endTrigger: '#footer',
		onUpdate: self => {
			const _slfscrl = self.scroll();
			if(self.direction == -1){
				document.body.classList.remove('scrldown');
			}else{
				if(_slfscrl > 10){
					if(self.prevSclrl < _slfscrl-1){
						document.body.classList.add('scrldown');
					}
					self.prevSclrl = _slfscrl;
				}
			}
			scrltop (_slfscrl);
		},
		onLeave: function(){
			body.addClass('scrlend');
		},
		onEnterBack: function(){
			body.removeClass('scrlend');
		},
	});
}
const scrltop = (_slfscrl) => {
	if(_slfscrl > 10){
		body.removeClass('scrltop');
		body.addClass('scrl');
	}else{
		body.removeClass('scrl');
		body.addClass('scrltop');
	}
}


async function ScrollSmootherKill() {
	await new Promise((resolve, reject) => {
		ScrollTrigger.killAll();
		resolve();
	})
}


var tar_offset;
function pageScroll(){
	if(window.matchMedia('(max-width:767px)').matches){
		tar_offset = 60;
	}else{
		tar_offset = 60;
	}
	$('a.anchor').on('click', function(e){
		e.preventDefault();
		nav_hide();
		var hrefPageUrl = $(this).prop("href");
		hrefPageUrl = hrefPageUrl.split("#")[0];
		var currentUrl = location.href;
		currentUrl = currentUrl.split("#")[0];
		if( hrefPageUrl == currentUrl ){
			let href = this.hash;
			gsap.to(window, { duration:0.6, scrollTo:{y:href, autoKill:false, offsetY:tar_offset}, ease:'power2'});	
		}
	});
}


let navshow_tl;
function nav_show(){
	body.addClass('navopen');
	navshow_tl.timeScale(1).play();
}
function nav_hide(){
	body.removeClass('navopen');
	navshow_tl.timeScale(2).reverse();
}
function navInit(){
	navshow_tl = gsap.timeline({
		paused: true,
		defaults: {
			ease: "power4.Out",
		}
	})
	.from('#navbg path', {
		scale: 0,
		duration: 0.4,
		stagger: 0.1,
		transformOrigin:"50% 50%",
	})
	.from('#nav_list .fade', {
		opacity: 0,
		y: 40,
		duration: 0.3,
		stagger: 0.08,
	}, '-=0.4');

	gsap.set('#main_nav', {opacity:1});

	$('#nav_btn').on('click', function(){
		if( body.hasClass('navopen') ){
			nav_hide();
		}else{
			nav_show();
		}
	});
}



const posArray_base = [];
const posArr = [];
function topkv(){	
	const baseUrl = 'https://api.figma.com/v1/';
	const figmaToken = 'figd_azQa-nkiKBvfui5Q9SY1gsM23ziyXYAYb1F_pqzu';
	const fileId = 'UNfUJwerAaLhUyHxqCbSg6';
	
	const fetchData = async () => {
		const res = await fetch(`${baseUrl}files/${fileId}/`, {
			headers: {
				'X-Figma-Token': figmaToken,
			},
		});
		return await res.json();
	};
	fetchData().then();
	
	const figmaData = fetchData().then((data) => {
		const frame = data.document.children[0].children[0];
		frame.children.forEach((group) => {
			group.children.forEach((child,i) => {
				if(group.name === 'pos1'){
					const _x = child.absoluteBoundingBox.x;
					const _y = child.absoluteBoundingBox.y;
					posArray_base.push({x:_x, y:_y});
				}else if(group.name === 'pos4'){
					const _x = child.absoluteBoundingBox.x;
					const _y = child.absoluteBoundingBox.y;
					posArr.push({x:_x, y:_y});
				}
			});
		});
		// console.log(posArr);
		// topkv_anime(data);
	});
}
function topkv_anime_pos(){	
	const posArray = [];
	const posfunc = function(target){
		document.querySelectorAll(target).forEach((path, i) => {
			const pos = path.getBoundingClientRect();
			const posx = pos.x;
			const posy = pos.y;
			posArray.push({x:posx, y:posy});
		});
	}
	posfunc('#topanime_svg path');

	const posArray2 = [];
	const posfunc2 = function(target){
		document.querySelectorAll(target).forEach((path, i) => {
			const pos = path.getBoundingClientRect();
			const posx = pos.x - posArray[i].x;
			const posy = pos.y - posArray[i].y;
			posArray2.push({x:posx, y:posy});
		});
	}
	posfunc2('#topanime_svg2 path');
}
function topkv_anime(){	
	const pos1 = [
    {'x': 127,'y': 652,'r':0},
    {'x': 651,'y': 216,'r':0},
    {'x': 378,'y': 225,'r':0},
    {'x': 501,'y': 275,'r':0},
    {'x': 785,'y': 463,'r':0},
    {'x': 77,'y': 429,'r':0},
    {'x': 701,'y': 378,'r':0},
    {'x': 201,'y': 222,'r':0},
    {'x': 429,'y': 156,'r':0},
    {'x': 575,'y': 439,'r':0},
    {'x': 326,'y': 576,'r':0},
    {'x': 236,'y': 385,'r':0},
    {'x': 403,'y': 418,'r':0},
	];
	const pos2 = [
    {'x': 647,'y': 33,'r': 152},
    {'x': 69,'y': 696,'r': 63},
    {'x': 235,'y': 642,'r': -91},
    {'x': 526,'y': 117,'r': -8},
    {'x': -21,'y': 820,'r': 0},
    {'x': 275,'y': 414,'r': 10},
    {'x': 192,'y': 612,'r': 0},
    {'x': 593,'y': 276,'r': -104},
    {'x': 792,'y': 281,'r': 127},
    {'x': -201,'y': 899,'r': -18},
    {'x': 646,'y': 136,'r': 62},
    {'x': 429,'y': 395,'r': 0},
    {'x': 777,'y': 54,'r': 0},
	]
	const pos3 = [
		{'x': 505,'y': 100,'r': 76},
		{'x': 610,'y': 505,'r': 107},
		{'x': 332,'y': 440,'r': -158},
		{'x': 430,'y': 126,'r': 147},
		{'x': 442,'y': 536,'r': 15},
		{'x': 107,'y': 386,'r': -53},
		{'x': 34,'y': 482,'r': 20},
		{'x': 456,'y': 355,'r': 113},
		{'x': 829,'y': 759,'r': 94},
		{'x': 562,'y': 236,'r': 152},
		{'x': 249,'y': 228,'r': -144},
		{'x': 181,'y': 273,'r': -115},
		{'x': 705,'y': 595,'r': -26},
	];
	const pos40 = [
    {"x": 100,"y": 25,'r': 175},
    {"x": 619,"y": 497,'r': -153},
    {"x": 322,"y": 441,'r': -170},
    {"x": 403,"y": 161,'r': 140},
    {"x": 447,"y": 527,'r': 14},
    {"x": 125,"y": 395,'r': 169},
    {"x": 33,"y": 484,'r': -9},
    {"x": 465,"y": 350,'r': 44},
    {"x": 866,"y": 720,'r': 77},
    {"x": 552,"y": 220,'r': -165},
    {"x": 269,"y": 214,'r': -173},
    {"x": 171,"y": 275,'r': -116},
    {"x": 717,"y": 600,'r': -25},
	];
	const pos4 = [
		{'x': -21,'y': 194,'r': 175},
		{'x': 193,'y': 583,'r': -153},
		{'x': 487,'y': 370,'r': -170},
		{'x': 432,'y': 570,'r': 140},
		{'x': 218,'y': 418,'r': 14},
		{'x': 202,'y': 179,'r': 169},
		{'x': 882,'y': 202,'r': -9},
		{'x': 422,'y': 157,'r': 44},
		{'x': 705,'y': 420,'r': 77},
		{'x': -22,'y': 590,'r': -165},
		{'x': 644,'y': 187,'r': -173},
		{'x': 812,'y': 598,'r': -116},
		{'x': 585,'y': 583,'r': -25},
	];
	const pos5 = [
		{'x': 373,'y': 349,'r': -34},
		{'x': 620,'y': 79,'r': -61},
		{'x': 634,'y': 733,'r': -49},
		{'x': 134,'y': 270,'r': 58},
		{'x': 759,'y': 670,'r': 24},
		{'x': 508,'y': 238,'r': -160},
		{'x': 580,'y': 417,'r': -96},
		{'x': 410,'y': 608,'r': -94},
		{'x': 728,'y': -45,'r': -61},
		{'x': 565,'y': 529,'r': 105},
		{'x': 10,'y': 356,'r': 54},
		{'x': 388,'y': 444,'r': -27},
		{'x': 207,'y': 410,'r': 165},
	];

	const svgPath = document.getElementById('topanime_svg path');

	document.querySelectorAll('#topanime_svg path').forEach((path, i) => {
		const set_tl = gsap.timeline({
			paused: true,
			defaults: {
				duration: 2,
				transformOrigin:"50% 50%",
				ease: "power4.inOut",
			}
		})
		.fromTo(path,
		{
			x: 'random(-100, 1200)',
			y: 'random(-100, 1200)',
			rotation: 'random(-360, 360)',
			scale: 2,
		},
		{
			x: pos1[i].x,
			y: pos1[i].y,
			rotation: pos1[i].r,
			scale: 1,
		})
		.fromTo('#kvtitle',
			{opacity:0},
			{opacity:1,duration:1,onComplete:function(){
				loop_tl.play();
			}
		});
		set_tl.play();

		const loop_tl = gsap.timeline({
			repeat: -1,
			paused: true,
			defaults: {
				duration: 2,
				delay: 0.6,
				transformOrigin:"50% 50%",
				ease: "power4.inOut",
			}
		})
		.to(path, {
			x: pos2[i].x,
			y: pos2[i].y,
			rotation: pos2[i].r,
		})
		.to(path, {
			x: pos3[i].x,
			y: pos3[i].y,
			rotation: pos3[i].r,
		})
		.to(path, {
			x: pos4[i].x,
			y: pos4[i].y,
			rotation: pos4[i].r,
		})
		.to(path, {
			x: pos5[i].x,
			y: pos5[i].y,
			rotation: pos5[i].r,
		})
		.to(path, {
			x: pos1[i].x,
			y: pos1[i].y,
			rotation: pos1[i].r,
		})
	});

	const scrlScale = () => {
		gsap.to('#g_top',
			{
				scale: 2,
				transformOrigin:"50% 50%",
				ease: 'power1.out',
				scrollTrigger: {
					trigger: '#topkv',
					start: 'bottom bottom',
					end: 'bottom top',
					scrub: 1,
				},
			}
		);
	}
	scrlScale();
}


function slider(){
	const swiper = new Swiper('.swiper', {
		slidesPerView: 1.1,
		centeredSlides: true,
		spaceBetween: 15,
		loop: true,
		autoplay: {
			delay: 4000,
			disableOnInteraction: false,
		},
		speed: 600,
		pagination: {
			el: '.sw_paging',
			clickable: true,
		},
		navigation: {
			nextEl: '.sw_next',
			prevEl: '.sw_prev',
		},
		breakpoints: {
			767: {
				slidesPerView: 3,
				centeredSlides: false,
			}
		}
	});
}

function training_slider(){
	const swiper = new Swiper('.swiper', {
		slidesPerView: 1,
		spaceBetween: 15,
		loop: true,
		autoplay: {
			delay: 4000,
			disableOnInteraction: false,
		},
		speed: 600,
		pagination: {
			el: '.sw_paging',
			clickable: true,
		},
		navigation: {
			nextEl: '.sw_next',
			prevEl: '.sw_prev',
		},
	});
}


function benefits() {
	gsap.timeline({
		defaults: {
			duration: 0.6,
			stagger: 0.1,
			ease: "power4.Out",
		},
		scrollTrigger: {
			trigger: '#benefits_list',
			start: "top bottom-=10%",
			once: true,
			onRefresh: self => self.progress === 1 && self.animation.progress(1)
		}
	})
	.fromTo('#benefits_list li',
		{ scaleX: 0},
		{ scaleX: 1}
	)
	.fromTo('#benefits_list .inner',
		{ opacity: 0},
		{ opacity: 1}, '-=0.6');
}


const contact_Cf7 = () => {
	if (wpcf7 && typeof wpcf7.init === 'function') { 
		document.querySelectorAll( '.wpcf7 > form' ).forEach( function( form ) {
			wpcf7.init( form );
		});
	}
}


function bgColor() {
	gsap.fromTo('body',
	{backgroundColor: '#F8F8F2'},
	{
		backgroundColor: '#F0FAFF',
		scrollTrigger: {
			start: 'top top',
			end: 'bottom bottom',
			scrub: 1,
		},
	});
}


var PageEvents = {
	loadFunc: function(){
		navInit();
	},
	commonFunc: function(){
		this.pjaxReadyFunc();
		winH = $(window).height();
		winW = $(window).width();	
		nav_hide();
		pageScroll();
		elFunc();
		bgColor();
		OBSERVER.observeEvents(1);
	},
	pjaxReadyFunc: function(){
	},
	topFunc: async function(){
		body.addClass('top');
		topkv_anime();
	},
	ourbusinessFunc: function(){
		slider();
	},
	benefitsFunc: function(){
		benefits();
	},
	trainingFunc: function(){
		training_slider();
	},
	formFunc: function(){
		body.addClass('formpage');
	},
	singleFunc: function(){
	}
};


function pjaxSettings(){

	barba.use(barbaPrefetch);

	const replaceHead = function(data){
		const head = document.head;
		const newPageRawHead = data.next.html.match(/<head[^>]*>([\s\S.]*)<\/head>/i)[0];
		const newPageHead = document.createElement('head');
		newPageHead.innerHTML = newPageRawHead;
		const removeHeadTags = [ 
			"meta[name='keywords']"
			,"meta[name='description']"
			,"meta[property^='og']"
			,"meta[name^='twitter']"
			,"meta[itemprop]"
			,"link[itemprop]"
			,"link[rel='prev']"
			,"link[rel='next']"
			,"link[rel='canonical']"
		].join(',');
		const headTags = head.querySelectorAll(removeHeadTags)
		for(let i = 0; i < headTags.length; i++ ){
			head.removeChild(headTags[i]);
		}
		const newHeadTags = newPageHead.querySelectorAll(removeHeadTags)
		for(let i = 0; i < newHeadTags.length; i++ ){
			head.appendChild(newHeadTags[i]);
		}
	}

	var eventDelete = function eventDelete(e) {
		if (e.currentTarget.href === window.location.href) {
			e.preventDefault()
			e.stopPropagation()
			return
		}
	}
	const links = Array.prototype.slice.call(document.querySelectorAll('a[href]'));
		links.forEach(function (link) {
			link.addEventListener('click', function (e) {
			eventDelete(e)
		}, false)
	})

	var gaPush = function gaPush(pagename) {
		if (typeof ga === 'function' && Barba.HistoryManager.history.length >= 1) {
			ga('send', 'pageview', pagename);
		}
		if (typeof gtag === 'function' && Barba.HistoryManager.history.length >= 1) { 
			// gtag('config', 'G-Xxxxxxxx', {'page_path': pagename}); 
		}
	}

	var preventSettings = function preventSettings(el) {
		let href = el.getAttribute('href');
		if(href == null){
			href = el.getAttribute('xlink:href');
		}
		if(el.classList.contains('nopj')){
			return true;
		}
		let site_url = location.protocol + '//' + location.host;
		if (!href.startsWith(site_url)) {
			el.setAttribute('target','_blank');
			return true;
		}
		let url = location.protocol + '//' + location.host + location.pathname;
		let extract_hash = href.replace(/#.*$/,"");
		if (href.startsWith(location.protocol + '//' + location.host)) {
			if (href.indexOf('#') > -1 && extract_hash != url ){
				return false;
			}
		}
		if (/\.(xlsx?|docx?|pptx?|pdf|jpe?g|png|gif|svg)/.test(href.toLowerCase())) {
			el.setAttribute('target','_blank');
			return true;
		}
	}

	async function scroll(data) {
		await new Promise((resolve, reject) => {
			let scrltarget = 0;
			if(data.trigger === "back") {
				scrltarget = scrollPosY;
			}
			scrollPosY = barba.history.current.scroll.y;
			if(data.trigger === "forward"){
				scrltarget = scrollForwardPosY;
			}

			if(location.hash){
				var anchor = document.querySelector( location.hash );
				if(anchor){
					var target = location.hash;
					gsap.to(window, { duration:0, scrollTo:{y:target, autoKill:false, offsetY:tar_offset}, ease:'power2', onComplete:function(){
						resolve();
					}});	
				}else{
					gsap.to(window, { duration:0.2, scrollTo:0, ease:'power2', onComplete:function(){
						resolve();
					}});
				}
			}else{
				gsap.to(window, { duration:0.2, scrollTo:scrltarget, ease:'power2', onComplete:function(){
					resolve();
				}});
			}
			
		})
	}

	barba.init({
		prevent: function prevent(_ref) {
			var el = _ref.el;
			return preventSettings(el);
		},
		timeout: 10000,			
		transitions: [{
			name: 'transition',
			sync:true,
			leave:function(data) {
				const done = this.async();
				gsap.to('footer', {opacity:0, duration:0.2})
				gsap.to(data.current.container, {opacity:0, duration:0.2, onComplete:function(){
					ScrollSmootherKill();
					done();
				}});
			},
			enter:function(data) {
				const done = this.async();
				const $elm = $(data.next.container);
				$elm.css({'opacity':0});
				done();
			}
		}]
	})

	barba.hooks.beforeLeave(() => {
		body.removeClass('ready top inpage navopen scrldown scrltop scrlend scrl onevh navwh formpage');
	});

	barba.hooks.beforeEnter((data) => {
		replaceHead(data);
		if( OBSERVER.handlers.length ){
			OBSERVER.clearEvents();
		}
		gaPush(location.pathname);
	});

	if (history.scrollRestoration) {
		history.scrollRestoration = 'manual';
	}
	var scrollPosY = 0;
	var scrollForwardPosY = 0;
	barba.hooks.leave((data) => {
		scrollForwardPosY = data.current.container.scroll.y;
	});
	barba.hooks.after((data) => {
		async function callerFun(){
			await scroll(data);
			pageCheck();
			if( document.getElementById('form_sec') ){
				contact_Cf7();
			}
			gsap.to('footer', {opacity:1, duration:0.6})
			gsap.to(data.next.container, {opacity:1, duration:0.6, onComplete:function(){
			}});
		}
		$('#container').imagesLoaded( function() {
			callerFun();
		});
	});
}

function pageCheck(){
	PageEvents.commonFunc();

	if( document.getElementById('top') ){
		PageEvents.topFunc();
	}else{
		body.addClass('inpage');
	}
	if( document.getElementById('our-business') ){
		PageEvents.ourbusinessFunc();
	}
	if( document.getElementById('benefits') ){
		PageEvents.benefitsFunc();
	}
	if( document.getElementById('training') ){
		PageEvents.trainingFunc();
	}
	if( document.getElementById('form_sec') ){
		PageEvents.formFunc();
	}
	if( document.getElementById('single') ){
		PageEvents.singleFunc();
	}
	body.addClass('ready');
}

function initialize(){
	window.addEventListener('DOMContentLoaded', function(){
		PageEvents.loadFunc();
		pageCheck();
		pjaxSettings();
	});
}

initialize();

})();
})( jQuery );

}